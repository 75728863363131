.container {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;

  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around !important;
  text-align: center !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.text-container {
  justify-content: center !important;
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.my-5 {
  margin-top: 3rem !important;
}

.root-container {
  background-color: #1a1724;
  overflow: hidden;
  color: #fff !important;
  padding: 0 !important;
}
